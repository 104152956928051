const keyNames = {
  name: "Name",
  time: "Beste Zeit",
  phone: "Telefon oder E-Mail",
  desc: "Beschreibung",
  company: "Firma",
  email: "E-Mail",
  message: "Ihre Nachricht",
  duration: "Dauer",
  techs: "Technologien"
};

export async function sendForm(form, type) {
  let body = `#### ${type} - ${form.isBot ? "bot" : "modal"}\n---\n`;

  Object.keys(form)
    .filter(key => key !== "agree" && key !== "isBot")
    .forEach(key => {
      body += `**${keyNames[key]}**: ${form[key]}\n`;
    });

  body = JSON.stringify({ text: body });

  await fetch("https://chat.99devs.de/hooks/afhes54oejngpfhshdak7cthjc", {
    method: "POST",
    mode: "no-cors",
    headers: {
      "Content-Type": "application/json"
    },
    body
  });
}
