<template>
  <div
    ref="messages"
    class="chatbot-messages d-flex flex-wrap"
    :class="showMessage && 'show'"
  >
    <div :class="postClasses">
      <img
        v-if="bot && showBot"
        alt="Icon Bot"
        class="robot position-absolute d-block"
        :src="`${styleBot}`"
      />
      <div class="d-block" :class="{ message: isLight }" v-html="message" />
    </div>
  </div>
</template>

<script>
import darkBot from "@/assets/img/chatbot/bot.svg";
import lightBot from "@/assets/img/chatbot/bot-blue.svg";
import { layoutState } from "@/components/layouts/layoutStyles";

export default {
  name: "ChatbotMessages",
  props: {
    message: {
      type: String
    },
    bot: {
      type: Boolean,
      default: false
    },
    showBot: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showMessage: false
    };
  },
  computed: {
    isLight() {
      return layoutState.theme === "light";
    },
    styleBot() {
      if (this.isLight) {
        return lightBot;
      }
      return darkBot;
    },
    theme() {
      return layoutState.theme;
    },
    postClasses() {
      return [
        `chatbot-messages--${layoutState.theme}`,
        this.bot ? "chatbot-messages--bot" : "user"
      ];
    }
  },
  mounted() {
    this.showMessage = true;
  }
};
</script>

<style scoped lang="scss">
@import "../../styles/imports";
@keyframes printingAnimation {
  to {
    transform: translateY(0px) scale(1);
    opacity: 0.5;
  }
}
@keyframes printingShowing {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes messageAnimation {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes messagesShowAnimation {
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.chatbot-messages {
  padding: 0;
  font-family: "Source Code Pro";
  scroll-behavior: smooth;
  font-size: $font-size-sm;
  overflow-y: auto;
  overflow-x: hidden;
  opacity: 0;
  transform: scale(0);
  &.show {
    animation: messagesShowAnimation 0.4s ease-in-out forwards;
  }
  @include breakpoint(md) {
    margin: 0 0 0 -29px;
    padding: 0 0 0 88px;
  }
  @include breakpoint(lg) {
    margin: 0 0 0 -88px;
  }
}
.chatbot-messages--dark {
  background: $colorInputDark;
}
.chatbot-messages--light {
  background-color: $colorInputLight;
}
.user .message {
  color: $text-color-dark;
}
.user {
  font-size: 16px;
  padding: 14px 28px;
  border-radius: 39px;
  margin-top: auto;
  flex-shrink: 0;
  margin-bottom: 5px;
  line-height: 34px;
  animation: messageAnimation 0.2s ease-in-out forwards;
  flex-direction: row;
  max-width: 100%;
  width: auto;
  position: relative;
  margin-left: auto;
  align-self: flex-end;
  @include breakpoint(md) {
    padding: 20px 36px;
    font-size: $font-size-sm;
  }
}

.chatbot-messages--bot {
  font-size: 16px;
  line-height: 26px;
  padding: 17px 28px;
  flex-shrink: 0;
  border-radius: 39px;
  margin: auto 0 5px 50px;
  animation: messageAnimation 0.2s ease-in-out forwards;
  color: $main-color;
  flex-direction: row;
  max-width: calc(100% - 62px);
  align-self: flex-start;
  position: relative;
  user-select: none;
  @include breakpoint(md) {
    padding: 20px 36px;
    line-height: 34px;
    margin: 0 0 5px 0;
    font-size: $font-size-sm;
  }
  img.robot {
    width: 50px;
    height: 50px;
    left: -52px;
    top: 8px;
    transform: scale(0.8);
    @include breakpoint(md) {
      left: -64px;
    }
  }
  .message {
    img {
      width: 18px;
      transform: translate(-10px, 0);
    }
    a {
      color: $main-color;
      transition: color 0.2s ease-in-out;
      font-family: "Source Code Pro", sans-serif;

      &:hover {
        color: $main-color;
      }
    }
  }
}
</style>
