export const Rules = {
  min: (length, errorMsg) => message => message.length < length && errorMsg,
  phoneOrEmail: errorMsg => message => {
    const parsedMessage = message.replace(/\s+/g, "");

    const phone = /^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/.test(
      parsedMessage
    );
    const email = /\S+@\S+\.\S+/.test(parsedMessage);
    if (phone || email) {
      return false;
    }
    return errorMsg;
  },
  equals: (string, errorMsg) => message =>
    message.toLowerCase() !== string && errorMsg
};
