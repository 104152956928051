<template>
  <div id="wave" class="mt-auto" :class="layoutState.theme">
    <span class="dot"></span>
    <span class="dot"></span>
    <span class="dot"></span>
  </div>
</template>

<script>
import { layoutState } from "@/components/layouts/layoutStyles";

export default {
  data() {
    return {
      layoutState
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/imports";
@keyframes messageAnimation {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.light {
  background: $colorInputLight;
}
.dark {
  background: $colorInputDark;
}
#wave {
  padding: 12px 36px;
  flex-shrink: 0;
  border-radius: 39px;
  margin-bottom: 5px;
  width: 105px;
  animation: messageAnimation 0.2s ease-in-out forwards;
  line-height: 34px;
  word-break: break-all;
  flex-direction: row;
  align-self: flex-start;
  position: relative;
  .dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 3px;
    background: $main-color;
    animation: wave 1.3s linear infinite;

    &:nth-child(2) {
      animation-delay: -1.1s;
    }

    &:nth-child(3) {
      animation-delay: -0.9s;
    }
  }
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }

  30% {
    transform: translateY(-15px);
  }
}
</style>
