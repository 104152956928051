<template>
  <div class="mb-4 mb-md-5">
    <EmphasizedInfoBlock class="mb-2 mb-md-5" v-html="$t('mainText')" />
    <ChatbotConversation @onFinish="answers => $emit('onFinish', answers)" />
  </div>
</template>

<script>
import EmphasizedInfoBlock from "@/components/startseite/EmphasizedInfoBlock";
import ChatbotConversation from "./ChatbotConversation";

export default {
  name: "ChatBot",
  components: {
    EmphasizedInfoBlock,
    ChatbotConversation
  },
  i18n: {
    messages: {
      de: {
        mainText: `
        <em>Sie wünschen einen Rückruf?</em>
        Hinterlassen Sie unserem digitalen Assistenten Ihre Kontaktdaten – wir
        werden uns dann mit Ihnen in Verbindung setzen.
        `
      },
      en: {
        mainText: `
        <em>Would you like us to call you back? </em>Leave
        your contact details with our digital assistant and
        we’ll get in touch with you.
        `
      }
    }
  }
};
</script>
