<template>
  <div
    ref="form"
    class="chatbot position-relative align-items-center d-flex flex-row"
    :class="[theme, { show: showAnimation }]"
  >
    <input
      ref="inputElement"
      v-model="input"
      class="min-w-100 max-w-100 h-100"
      :class="`chatbot-input--${theme}`"
      spellcheck="false"
      :placeholder="$t('yourMessage')"
      @keyup.enter="onSend"
    />
    <button
      class="chatbot-button d-flex align-items-center position-absolute justify-content-center border-0"
      :class="`chatbot-button--${theme}`"
      @click="onSend"
    >
      <svg viewBox="0 0 885 759">
        <polygon points="0 759 885 379 0 0 0 295 632 379 0 463" />
      </svg>
    </button>
  </div>
</template>

<script>
import { layoutState } from "@/components/layouts/layoutStyles";

export default {
  name: "ChatbotInput",
  data: () => ({
    showAnimation: true,
    input: ""
  }),
  computed: {
    theme() {
      return layoutState.theme;
    }
  },
  methods: {
    onSend() {
      this.$emit("send", this.input);
      this.input = "";
    }
  },
  i18n: {
    messages: {
      de: {
        yourMessage: "Ihre Nachricht..."
      },
      en: {
        yourMessage: "Leave a message..."
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../styles/imports";
* {
  font-family: "Source Code Pro", sans-serif;
}
@keyframes formShowAnimation {
  from {
    width: 68px;
  }
  to {
    width: 100%;
  }
}
input {
  font-family: "Source Code Pro", sans-serif;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $primary;
  }
}
.dark {
  background: $colorInputDark;
}
.light {
  background: $colorInputLight;
}

.chatbot {
  height: 62px;
  border-radius: 39px;
  width: 68px;
  &.show {
    animation: formShowAnimation 0.4s ease-in-out forwards;
  }
  @include breakpoint(md) {
    height: 68px;
  }
}
.chatbot-button--light,
.chatbot-button--dark {
  outline: none;
  width: 56px;
  height: 56px;
  background: $main-color;
  border-radius: 28px;
  cursor: pointer;
  margin-right: 4px;
  transition: 0.2s ease-in-out;
  right: 0;
  z-index: 2;
  &:hover {
    box-shadow: $box-gallery-hover;
    polygon {
      fill: $body-bg-dark;
    }
  }
  svg {
    width: 18px;
    height: 20px;
    transform: translateX(3px);
  }
}
.chatbot-button--light {
  color: $text-color-bright;
  svg {
    polygon {
      transition: 0.2s ease-in-out;
      fill: $white;
    }
  }
}
.chatbot-button--dark {
  color: $text-color-dark;
  svg {
    polygon {
      transition: 0.2s ease-in-out;
      fill: $white;
    }
  }
}

.chatbot-input--light,
.chatbot-input--dark {
  outline: none;
  z-index: 1;
  font-family: "Source Code Pro", sans-serif;
  font-size: 1rem;
  background: transparent;
  border: none;
  flex: 1;
  padding: 0 92px 0 28px;
  letter-spacing: normal;
  border-radius: 39px;
  transition: 0.2s ease-in-out;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @include breakpoint(md) {
    letter-spacing: 3px;
    padding: 0 calc(38px + 56px) 0 38px;
    font-size: $font-size-sm;
  }
}
.chatbot-input--dark {
  color: $white;
  &::placeholder {
    color: $white;
  }
}
</style>
