<template>
  <div>
    <div ref="chatbotMessages" class="chatbot-conversation">
      <ChatbotMessages
        v-for="(item, index) in arrayMessage"
        :key="index"
        :message="item.message"
        :bot="item.bot"
        :showBot="item.showBot"
      />
      <WritingAnimation v-if="isWriting" />
    </div>
    <ChatbotInput @send="message" />
  </div>
</template>

<script>
import ChatbotMessages from "./ChatbotMessages";
import ChatbotInput from "./ChatbotInput";
import WritingAnimation from "./WritingAnimation";

import { Rules } from "./conversationRules";

export default {
  name: "ChatbotConversation",
  components: {
    ChatbotMessages,
    ChatbotInput,
    WritingAnimation
  },
  data() {
    return {
      current: 0,
      conversation: [],
      arrayMessage: [],
      isWriting: false,
      questions: [
        {
          messages: this.$t(`messages.0`),
          validation: []
        },
        {
          messages: this.$t(`messages.1`),
          validation: [
            Rules.equals(
              this.$t("validations.yes"),
              this.$t("validations.equals")
            )
          ]
        },
        {
          messages: this.$t(`messages.2`),
          validation: [
            Rules.min(3, this.$t("validations.minLength", { length: 3 }))
          ],
          name: "name"
        },
        {
          messages: this.$t(`messages.3`),
          validation: [],
          name: "time"
        },
        {
          messages: this.$t(`messages.4`),
          validation: [Rules.phoneOrEmail(this.$t("validations.phoneOrEmail"))],
          name: "phone"
        },
        {
          messages: this.$t(`messages.4`),
          validation: [],
          name: "desc"
        }
      ]
    };
  },
  computed: {
    currentQuestion() {
      return this.conversation[this.current];
    },
    isConversationFinished() {
      return this.conversation.length - 1 === this.current;
    }
  },
  methods: {
    sleep(n) {
      return new Promise(resolve => setTimeout(resolve, n));
    },

    async message(data, bot = false, showBot = false) {
      if (!data) return;

      const { chatbotMessages } = this.$refs;

      if (bot) {
        await this.sleep(500);
        this.isWriting = true;

        setTimeout(() => {
          if (chatbotMessages) {
            chatbotMessages.scrollTop = chatbotMessages.scrollHeight;
          }
        }, 10);

        await this.sleep(1500);
        this.isWriting = false;
      }

      this.arrayMessage.push({
        bot,
        message: data,
        showBot
      });
      if (!bot) this.next(data);

      setTimeout(() => {
        if (chatbotMessages) {
          chatbotMessages.scrollTop = chatbotMessages.scrollHeight;
        }
      }, 100);
    },
    next(currentAnswer) {
      const currentValidation = this.currentQuestion.validation;

      if (!currentValidation) return;

      const error = currentValidation.find(rule => {
        return rule(currentAnswer);
      });
      if (!error) {
        this.currentQuestion.answer = currentAnswer;
        if (this.isConversationFinished) {
          this.finish();
        } else {
          this.current += 1;
          this.currentQuestion.messages.forEach((m, i) =>
            this.message(m, true, i === 0)
          );
        }
      } else {
        this.message(error(currentAnswer), true, true);
      }
    },
    finish() {
      const answers = [];
      this.conversation.forEach(c => {
        if (c.name) {
          answers[c.name] = c.answer;
        }
      });
      answers.isBot = true;
      this.$emit("onFinish", answers);
    }
  },
  created() {
    this.conversation = this.questions;
    if (this.currentQuestion) {
      this.currentQuestion.messages.forEach((m, i) =>
        this.message(m, true, i === 0)
      );
    }
  },
  i18n: {
    messages: {
      de: {
        messages: [
          ["Hallo Besucher 😊"],
          [
            `Sind Sie damit einverstanden, dass Ihre Daten elektronisch erhoben und gespeichert werden?
            Die Datenschutzerklärung können Sie
            <a style='text-decoration: underline' href="/datenschutz" target="_blank">hier</a>
            nachlesen.`,
            `Falls Sie unserer Datenschutzerklärung zustimmen, so antworten Sie mit einem „Ja“.`
          ],
          ["Bitte nennen Sie uns Ihren Namen."],
          ["Wann können wir Sie am besten erreichen?"],
          [
            "Wie sollen wir uns mit Ihnen in Verbindung setzen? Bitte teilen Sie uns Ihre E-Mail Adresse oder Ihre Telefonnummer mit."
          ],
          [
            "Vielen Dank! 👍",
            "Sie können uns jetzt noch Ihr Anliegen mit ein paar Sätzen beschreiben – bis bald!"
          ]
        ],
        validations: {
          yes: "ja",
          equals: `Rufen Sie uns einfach an, dann können wir Ihr Anliegen direkt am Telefon besprechen: 0631 205755221`,
          phoneOrEmail: `Telefon oder E-Mail nicht gültig`,
          minLength: `Mindestens {length} Zeichen`
        }
      },
      en: {
        messages: [
          ["Hello visitor 😊"],
          [
            `Do you agree that your data may be collected and stored electronically? You can read the privacy policy <a style='text-decoration: underline' href="/privacy" target="_blank">here</a>.`,
            `If you agree to our privacy policy, please answer with a "Yes".`
          ],
          ["Please tell us your name."],
          ["When can we reach you best?"],
          [
            "How should we get in touch with you? Please provide us with your e-mail address or your telephone number."
          ],
          [
            "Thanks a lot! 👍",
            "Now you can describe your request with a few sentences - see you soon!"
          ]
        ],
        validations: {
          yes: "yes",
          equals: `Just give us a call and we can discuss your request directly on the phone: +49 631 205755221`,
          phoneOrEmail: `Phone or e-mail not valid`,
          minLength: `At least {length} characters`
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../styles/imports";
.chatbot-conversation {
  padding-left: 4em;
  width: calc(100% + 4em);
  margin-left: -4em;
  scroll-behavior: smooth;
  max-height: 330px;
  min-height: 0;
  overflow-y: auto;
  margin-bottom: 10px;
  &::-webkit-scrollbar-track {
    background-color: $colorInputLight;
  }
  &::-webkit-scrollbar {
    width: 6px;
    background-color: $colorInputLight;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: $main-color;
  }
}
</style>
