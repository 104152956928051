export function metaInfo(title, descr, img = null) {
  if (title.length > 60) {
    console.error(
      `Title [${title.trim()}] has length ${title.length} of max. 60`
    );
  }
  if (descr.length > 130) {
    console.error(
      `Descr [${descr.trim()}] has length ${descr.length} of max. 130`
    );
  }

  if (!descr || !title) {
    throw new Error("Please provide a description and a title");
  }

  const fullUrl = window.location.href;
  const image = img || "https://mediaformat.de/mfshareimage.png";

  return {
    title,
    link: [
      {
        rel: "canonical",
        href: fullUrl
      }
    ],
    meta: [
      {
        name: "description",
        content: descr
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:url",
        content: fullUrl
      },
      {
        property: "og:description",
        content: descr
      },
      {
        property: "og:image",
        content: image
      },
      {
        itemprop: "name",
        content: title
      },
      {
        itemprop: "description",
        content: descr
      },
      {
        itemprop: "image",
        content: image
      }
    ]
  };
}
