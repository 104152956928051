<template>
  <div class="emphasized-info" :class="styleClass">
    <slot />
  </div>
</template>

<script>
import { layoutState } from "@/components/layouts/layoutStyles";

export default {
  name: "EmphasizedInfoBlock",
  computed: {
    styleClass() {
      return layoutState.theme;
    }
  }
};
</script>

<style lang="scss">
@import "../../styles/imports";

.emphasized-info {
  background: transparent;
  line-height: $line-height-base;
  color: $text-color-bright;
  font-weight: $font-weight-normal;
  margin: 0;

  em {
    color: $main-color;
    font-weight: $font-weight-bold;
    font-style: normal;
  }

  &.light {
    color: $text-color-dark;
    em {
      color: $text-color-dark;
    }
  }
}
</style>
